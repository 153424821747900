window.onload = function() 
{
    const user_info_time = 5000; // 5 segundos
    let interval  = 0;
    let sessionid = "";

    // Function check online users
    const check_online_user = () => {
        if(typeof CPF_API === 'string' && CPF_API != "" && typeof CPF_LOGGED === 'string' 
            && CPF_LOGGED != "" && typeof CPF_TRACE === 'object')
        {
            // console.log("CPF: ", CPF_LOGGED);
            // console.log("TRACE: ", CPF_TRACE);

            $.post(CPF_API+"/user_info", {int:interval, sid:sessionid, cpfCnpj:CPF_LOGGED, trace:JSON.stringify(CPF_TRACE)}, function(data)
            {    
                sessionid = data.sid;
                
                if(data.rst === true) interval = 0;
                else interval+=(user_info_time/1000);
            });
        }
    }

    // setInterval for check_online_user
    // setInterval(check_online_user, user_info_time);
}